(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('AssessmentMethodCtrl', AssessmentMethodCtrl);

    function AssessmentMethodCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        function get() {
            $http.get(_env.apiUrl + '/assessment-methods').then(function (response) {
                $scope.items = response.data;
            });
        }

        get();
    }
})();