(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('CategoryCtrl', CategoryCtrl);

    function CategoryCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        function get() {
            $http.get(_env.apiUrl + '/staff-categories').then(function (response) {
                $scope.items = response.data;
            });
        }

        get();

        $scope.edit = function (item) {
            var data = {
                title: item && item.title
            };
            Swal.fire({
                title: item ? 'Edit Category' : 'Add New Category',
                input: 'text',
                inputValue: data.title,
                inputPlaceholder: 'Type title here',
                inputValidator: function (value) {
                    if (!value) {
                        return 'You need to write some title!'
                    }
                },
                showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Save',
                reverseButtons: true
            }).then(function(result) {
                if (result.value && result.value.trim()) {
                    var data = {
                        'title': result.value.trim()
                    };

                    if(item) {
                        $http.put(_env.apiUrl + '/staff-categories/' + item.id, data).then(function(response) {
                            get();
                        }, function (reason) {
                            Swal.fire({
                                type: 'error',
                                title: 'Oops...',
                                text: JSON.stringify(reason.data, null, 2)
                            });
                        });
                    } else {
                        $http.post(_env.apiUrl + '/staff-categories', data).then(function(response) {
                            get();
                        }, function (reason) {
                            Swal.fire({
                                type: 'error',
                                title: 'Oops...',
                                text: JSON.stringify(reason.data, null, 2)
                            });
                        });
                    }
                }
            });
        }

        $scope.delete = function (item) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/staff-categories/' + item.id).then(function () {
                        get();
                        Swal.fire({
                            type: 'success',
                            title: 'Item successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }
    }
})();