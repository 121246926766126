(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('PositionListCtrl', PositionListCtrl);

    function PositionListCtrl($scope, $state, $location, $http, AuthenticationService, _env) {
        $http.get(_env.apiUrl + '/positions').then(function (response) {
            $scope.positions = response.data;
        });
        
        $scope.show = function (position) {
            Swal.fire({
                title: position.title,
                text: position.duties,
                customClass: 'position-details'
            });
        }
    }
})();