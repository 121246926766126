(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('FeedCtrl', FeedCtrl);

    function FeedCtrl($scope, $state, $stateParams, $location, $http, AuthenticationService, _env) {

        function getStaff() {
            $http.get(_env.apiUrl + '/staffs').then(function (response) {
                $scope.staffs = response.data;
            });
        }

        function getEvents(refresh) {
            if (refresh) {
                $scope.eventsPageData = {
                    totalItems: 0,
                    page: 0,
                    hasMoreItems: true,
                    items: [],
                    groupedItems: []
                };
            }

            if (!$scope.eventsPageData.hasMoreItems) {
                return;
            }

            $scope.eventsPageData.page += 1;
            $http.get(_env.apiUrl + '/events?page=' + $scope.eventsPageData.page).then(function (response) {
                $scope.eventsPageData.totalItems = response.data.total;
                $scope.eventsPageData.items = $scope.eventsPageData.items.concat(response.data.data);
                $scope.eventsPageData.hasMoreItems = response.data.total > $scope.eventsPageData.items.length;

                $scope.eventsPageData.groupedItems = [];
                _.forEach(
                    _.groupBy($scope.eventsPageData.items, function (event) {
                        return formatDate(event.created_at, 'YYYY-MM-DD');
                    }), function (events, key) {
                        $scope.eventsPageData.groupedItems.push({
                            type: 'time',
                            created_at: events[0].created_at
                        });
                        _.forEach(events, function (event) {
                            $scope.eventsPageData.groupedItems.push(event);
                        })
                    });
            });
        }

        $scope.eventsPageData = {
            totalItems: 0,
            page: 0,
            hasMoreItems: true,
            items: [],
            groupedItems: []
        };

        getStaff();
        getEvents(true);

        $scope.showNoteForm = function() {
            $scope.chooseStaffControl = {
                data: _.map($scope.staffs, function (staff) {
                    return {
                        text: staff.name,
                        id: staff.id
                    };
                }),
                onSelect: function (selectedData) {
                    if (selectedData.id >= 0) {
                        $scope.note.staff_id = selectedData.id;
                    }
                }
            };
            $scope.chooseStaffControl.data.unshift({id: -1, text: "Choose Staff"});
            $scope.note = {};
            $('#modal-note').modal('show');
        }

        $scope.submitNoteForm = function () {
            if (!$scope.note) {
                return;
            }

            if (!$scope.note.text|| !$scope.note.text.trim()) {
                return;
            }

            if (!$scope.note.staff_id || $scope.note.staff_id < 0) {
                return;
            }

            $scope.proccessing = true;

            $http.post(_env.apiUrl + '/notes', $scope.note).then(function(response) {
                $('#modal-note').modal('hide');
                getEvents(true);
                $scope.proccessing = false;
                $scope.note = null;
                Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Successfully saved!',
                    showConfirmButton: false,
                    timer: 1000
                });
            });
        }


        $(window).scroll(function() {
            if($(window).scrollTop() + $(window).height() >= $(document).height()) {
                getEvents();
            }
        });

    }
})();