(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('EditStaffCtrl', EditStaffCtrl);

    function EditStaffCtrl($scope, $state, $location, $http, $stateParams, _env, Uploader) {

        $http.get(_env.apiUrl + '/staffs/' + $stateParams.id).then(function (response) {
            $scope.staff = response.data;
            $scope.staff.do_not_want_indicate_social_networks = $scope.staff.do_not_want_indicate_social_networks == 1;
            _.forEach(response.data.relatives, function() {
                $scope.errors.relatives.push({
                    type: false,
                    name: false,
                    birthday: false,
                    work: false,
                    address: false
                });
            });
            _.forEach(response.data.educations, function() {
                $scope.errors.educations.push({
                    start_date: false,
                    end_date: false,
                    title: false,
                    department: false,
                    speciality: false
                });
            });
            _.forEach(response.data.work_experiences, function() {
                $scope.errors.work_experiences.push({
                    start_date: false,
                    end_date: false,
                    title: false,
                    position: false
                });
            });
            _.forEach(response.data.social_networks, function() {
                $scope.errors.social_networks.push({
                    link: false
                });
            });
        });

        $scope.addRelative = function (type) {
            $scope.staff.relatives.push({ type: type });
            $scope.errors.relatives.push({});
        }
        $scope.removeRelative = function (atIndex) {
            $scope.staff.relatives.splice(atIndex, 1);
            $scope.errors.relatives.splice(atIndex, 1);
        }

        $scope.addEducation = function (type) {
            $scope.staff.educations.push({ type: type });
            $scope.errors.educations.push({});
        }
        $scope.removeEducation = function (atIndex) {
            $scope.staff.educations.splice(atIndex, 1);
            $scope.errors.educations.splice(atIndex, 1);
        }

        $scope.addWorkExperience = function () {
            $scope.staff.work_experiences.push({});
            $scope.errors.work_experiences.push({});
        }
        $scope.removeWorkExperience = function (atIndex) {
            $scope.staff.work_experiences.splice(atIndex, 1);
            $scope.errors.work_experiences.splice(atIndex, 1);
        }

        $scope.addSocialNetwork = function () {
            $scope.staff.social_networks.push({});
            $scope.errors.social_networks.push({});

            $scope.staff.do_not_want_indicate_social_networks = false;
        }
        $scope.removeSocialNetwork = function (atIndex) {
            $scope.staff.social_networks.splice(atIndex, 1);
            $scope.errors.social_networks.splice(atIndex, 1);

            if ($scope.staff.social_networks.length == 0) {
                $scope.staff.do_not_want_indicate_social_networks = true;
            }
        }

        $scope.$watch("staff.do_not_want_indicate_social_networks", function (newValue, oldValue) {
            if (newValue == false && $scope.staff.social_networks.length == 0) {
                $scope.addSocialNetwork();
            }
        });

        $scope.errors = {
            'first_name': false,
            'last_name': false,
            'middle_name': false,

            'birthday': false,
            'birthplace': false,

            'address': false,
            'citizenship': false,

            'mobile_phone': false,
            'secondary_contact_phone': false,

            'marital_status': false,
            'living_condition': false,
            'photo': false,
            'passport': false,
            'tax_number': false,
            relatives: [],
            work_experiences: [],
            social_networks: [{}],
            educations: []
        }

        function hasError() {
            return _.filter(_.values($scope.errors), function (error) {
                if (Array.isArray(error)) {
                    var result = false;
                    _.forEach(error, function (e) {
                        if (_.filter(_.values(e), function (f) { return f}).length > 0) {
                            result = true;
                        }
                    });
                    return result;
                } else {
                    return error;
                }
            }).length > 0;
        }

        $scope.getErrorable = function(arr, field, index) {
            return arr[index][field];
        }
        $scope.save = function () {
            if (!$scope.staff) return;

            $scope.errors.first_name = !$scope.staff.first_name;
            $scope.errors.last_name = !$scope.staff.last_name;
            $scope.errors.middle_name = !$scope.staff.middle_name;

            $scope.errors.birthday = !$scope.staff.birthday;
            $scope.errors.birthplace = !$scope.staff.birthplace;
            $scope.errors.address = !$scope.staff.address;
            $scope.errors.citizenship = !$scope.staff.citizenship;
            $scope.errors.mobile_phone = !$scope.staff.mobile_phone;
            $scope.errors.secondary_contact_phone = !$scope.staff.secondary_contact_phone;
            $scope.errors.marital_status = !$scope.staff.marital_status;
            $scope.errors.living_condition = !$scope.staff.living_condition;
            $scope.errors.photo = !$scope.staff.photo;
            $scope.errors.passport = !$scope.staff.passport;
            $scope.errors.tax_number = !$scope.staff.tax_number;

            var i = 0;
            _.forEach($scope.staff.relatives, function (relative) {
                $scope.errors.relatives[i].type = !relative.type;
                $scope.errors.relatives[i].name = !relative.name;
                $scope.errors.relatives[i].birthday = !relative.birthday;
                $scope.errors.relatives[i].work = !relative.work;
                $scope.errors.relatives[i].address = !relative.address;
                i++;
            });
            i = 0;
            _.forEach($scope.staff.educations, function (education) {
                $scope.errors.educations[i].start_date = !education.start_date;
                $scope.errors.educations[i].end_date = !education.end_date;
                $scope.errors.educations[i].title = !education.title;

                if (education.type == 'Среднее профессиональное') {
                    $scope.errors.educations[i].department = !education.department;
                } else if (education.type == 'Высшее') {
                    $scope.errors.educations[i].department = !education.department;
                    $scope.errors.educations[i].speciality = !education.speciality;
                }
                i++;
            });
            i = 0;
            _.forEach($scope.staff.work_experiences, function (work_experience) {
                $scope.errors.work_experiences[i].start_date = !work_experience.start_date;
                $scope.errors.work_experiences[i].end_date = !work_experience.end_date;
                $scope.errors.work_experiences[i].title = !work_experience.title;
                $scope.errors.work_experiences[i].position = !work_experience.position;
                i++;
            });
            i = 0;
            _.forEach($scope.staff.social_networks, function (social_network) {
                $scope.errors.social_networks[i].link = !social_network.link;
                i++;
            });

            if (hasError()) return;

            uploadPhoto(function () {
                uploadPassport(function () {
                    uploadTaxNumber(function () {
                        uploadInternationalPassport(function () {
                            $http.put(_env.apiUrl + '/staffs/' + $scope.staff.id, $scope.staff).then(function (response) {
                                $scope.processing = false;
                                $scope.error = false;
                                $scope.success = false;
                                $scope.thankupage = true;

                                Swal.fire({
                                    type: 'success',
                                    title: 'Staff successfully saved',
                                    position: 'top-end',
                                    timer: 1000
                                });
                            }, function (reason) {
                                Swal.fire({
                                    type: 'error',
                                    title: 'Oops...',
                                    text: JSON.stringify(reason, null, 2)
                                });
                            });
                        });
                    });
                });
            });
        }

        function uploadPhoto(callback) {
            if ($scope.staff.newPhoto) {
                Uploader.uploadFilesToUrl([$scope.staff.newPhoto], _env.apiUrl + '/uploads?attachable_type=staff_photo&attachable_id=' + $scope.staff.id, '', {
                    'success': function (uploadedFiles) {
                        if (callback) callback();
                    },
                    'error': function (error) {
                        $scope.processing = false;
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(error, null, 2)
                        });
                    }
                });
            } if (callback) callback();
        }

        function uploadPassport(callback) {
            if ($scope.staff.passport && $scope.staff.passport.attachable_id === undefined) {
                Uploader.uploadFilesToUrl([$scope.staff.passport], _env.apiUrl + '/uploads?attachable_type=passport&attachable_id=' + $scope.staff.id, '', {
                    'success': function () {
                        if (callback) callback();
                    },
                    'error': function (error) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(error, null, 2)
                        });
                    }
                });
            } else if (callback) {
                callback();
            }
        }

        function uploadTaxNumber(callback) {
            if ($scope.staff.tax_number && $scope.staff.tax_number.attachable_id === undefined) {
                Uploader.uploadFilesToUrl([$scope.staff.tax_number], _env.apiUrl + '/uploads?attachable_type=tax_number&attachable_id=' + $scope.staff.id, '', {
                    'success': function () {
                        if (callback) callback();
                    },
                    'error': function (error) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(error, null, 2)
                        });
                    }
                });
            } else if (callback) {
                callback();
            }
        }

        function uploadInternationalPassport(callback) {
            if ($scope.staff.international_passport && $scope.staff.international_passport.attachable_id === undefined) {
                Uploader.uploadFilesToUrl([$scope.staff.international_passport], _env.apiUrl + '/uploads?attachable_type=international_passport&attachable_id=' + $scope.staff.id, '', {
                    'success': function () {
                        if (callback) callback();
                    },
                    'error': function (error) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(error, null, 2)
                        });
                    }
                });
            } else if (callback) {
                callback();
            }
        }

        $scope.fileDropped = function() {
            var file = $scope.uploadedFile;
            if (!file) return;

            var reader = new FileReader();

            reader.onload = function (e) {
                file.local_src = e.target.result;
                $scope.staff.newPhoto = file;

                $scope.$apply();
            };
            reader.readAsDataURL(file);

            $scope.uploadedFile = null;
        };

        $scope.getPhoto = function () {
            if($scope.staff && $scope.staff.newPhoto) {
                return $scope.staff.newPhoto.local_src;
            } else if ($scope.staff && $scope.staff.photo) {
                return $scope.staff.photo.file_url;
            } else {
                return 'img/profile.png';
            }
        }
    }
})();