(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('AddStaffsCtrl', AddStaffsCtrl);

    function AddStaffsCtrl($scope, $state, $location, $http, $stateParams, _env, Uploader) {

        $scope.success = true;
        $scope.error = '';

        $http.get(_env.apiUrl + '/positions').then(function (response) {
            $scope.positions = response.data;
        });

        $http.get(_env.apiUrl + '/departments').then(function (response) {
            $scope.departments = response.data;
        });

        $scope.staff = {
            relatives: [],
            educations: [],
            work_experiences: [],
            social_networks: [{}],
            invitation_code: $location.$$search.invitation_code
        };

        $scope.addRelative = function (type) {
            $scope.staff.relatives.push({ type: type });
            $scope.errors.relatives.push({});
        }
        $scope.removeRelative = function (atIndex) {
            $scope.staff.relatives.splice(atIndex, 1);
            $scope.errors.relatives.splice(atIndex, 1);
        }

        $scope.addEducation = function (type) {
            $scope.staff.educations.push({ type: type });
            $scope.errors.educations.push({});
        }
        $scope.removeEducation = function (atIndex) {
            $scope.staff.educations.splice(atIndex, 1);
            $scope.errors.educations.splice(atIndex, 1);
        }

        $scope.addWorkExperience = function () {
            $scope.staff.work_experiences.push({});
            $scope.errors.work_experiences.push({});
        }
        $scope.removeWorkExperience = function (atIndex) {
            $scope.staff.work_experiences.splice(atIndex, 1);
            $scope.errors.work_experiences.splice(atIndex, 1);
        }

        $scope.addSocialNetwork = function () {
            $scope.staff.social_networks.push({});
            $scope.errors.social_networks.push({});

            $scope.staff.do_not_want_indicate_social_networks = false;
        }
        $scope.removeSocialNetwork = function (atIndex) {
            $scope.staff.social_networks.splice(atIndex, 1);
            $scope.errors.social_networks.splice(atIndex, 1);

            if ($scope.staff.social_networks.length == 0) {
                $scope.staff.do_not_want_indicate_social_networks = true;
            }
        }

        $scope.$watch("staff.do_not_want_indicate_social_networks", function (newValue, oldValue) {
            if (newValue == false && $scope.staff.social_networks.length == 0) {
                $scope.addSocialNetwork();
            }
        });

        $scope.errors = {
            'personal_email': false,
            'department_id': false,
            'position_id': false,

            'first_name': false,
            'last_name': false,
            'middle_name': false,

            'birthday': false,
            'birthplace': false,

            'address': false,
            'citizenship': false,

            'mobile_phone': false,
            'secondary_contact_phone': false,

            'marital_status': false,
            'living_condition': false,
            'photo': false,
            'passport': false,
            'tax_number': false,
            relatives: [],
            work_experiences: [],
            social_networks: [{}],
            educations: []
        }

        function hasError() {
            return _.filter(_.values($scope.errors), function (error) {
                if (Array.isArray(error)) {
                    var result = false;
                    _.forEach(error, function (e) {
                        if (_.filter(_.values(e), function (f) { return f}).length > 0) {
                            result = true;
                        }
                    });
                    return result;
                } else {
                    return error;
                }
            }).length > 0;
        }

        $scope.getErrorable = function(arr, field, index) {
            return arr[index][field];
        }
        $scope.save = function () {
            if (!$scope.staff) return;

            $scope.errors.personal_email = !$scope.staff.personal_email;
            $scope.errors.department_id = !$scope.staff.department_id;
            $scope.errors.position_id = !$scope.staff.position_id;


            $scope.errors.first_name = !$scope.staff.first_name;
            $scope.errors.last_name = !$scope.staff.last_name;
            $scope.errors.middle_name = !$scope.staff.middle_name;

            $scope.errors.birthday = !$scope.staff.birthday;
            $scope.errors.birthplace = !$scope.staff.birthplace;
            $scope.errors.address = !$scope.staff.address;
            $scope.errors.citizenship = !$scope.staff.citizenship;
            $scope.errors.mobile_phone = !$scope.staff.mobile_phone;
            $scope.errors.secondary_contact_phone = !$scope.staff.secondary_contact_phone;
            $scope.errors.marital_status = !$scope.staff.marital_status;
            $scope.errors.living_condition = !$scope.staff.living_condition;
            $scope.errors.photo = !$scope.staff.photo;
            $scope.errors.passport = !$scope.staff.passport;
            $scope.errors.tax_number = !$scope.staff.tax_number;

            var i = 0;
            _.forEach($scope.staff.relatives, function (relative) {
                $scope.errors.relatives[i].type = !relative.type;
                $scope.errors.relatives[i].name = !relative.name;
                $scope.errors.relatives[i].birthday = !relative.birthday;
                $scope.errors.relatives[i].work = !relative.work;
                $scope.errors.relatives[i].address = !relative.address;
                i++;
            });
            i = 0;
            _.forEach($scope.staff.educations, function (education) {
                $scope.errors.educations[i].start_date = !education.start_date;
                $scope.errors.educations[i].end_date = !education.end_date;
                $scope.errors.educations[i].title = !education.title;

                if (education.type == 'Среднее профессиональное') {
                    $scope.errors.educations[i].department = !education.department;
                } else if (education.type == 'Высшее') {
                    $scope.errors.educations[i].department = !education.department;
                    $scope.errors.educations[i].speciality = !education.speciality;
                }
                i++;
            });
            i = 0;
            _.forEach($scope.staff.work_experiences, function (work_experience) {
                $scope.errors.work_experiences[i].start_date = !work_experience.start_date;
                $scope.errors.work_experiences[i].end_date = !work_experience.end_date;
                $scope.errors.work_experiences[i].title = !work_experience.title;
                $scope.errors.work_experiences[i].position = !work_experience.position;
                i++;
            });
            i = 0;
            _.forEach($scope.staff.social_networks, function (social_network) {
                $scope.errors.social_networks[i].link = !social_network.link;
                i++;
            });

            if (hasError()) return;

            var today = new Date();

            $scope.staff.name = $scope.staff.first_name;
            $scope.staff.phone = $scope.staff.mobile_phone;
            $scope.staff.email = $scope.staff.personal_email;
            $scope.staff.date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            $scope.staff.note = 'note';

            $http.post(_env.apiUrl + '/staffs/interview', $scope.staff).then(function(response) {
                $scope.staff.id = response.data.id;
                uploadPhoto(function () {
                    uploadPassport(function () {
                        uploadTaxNumber(function () {
                            uploadInternationalPassport(function () {
                                $http.post(_env.apiUrl + '/profile', $scope.staff).then(function () {
                                    $scope.processing = false;
                                    $scope.error = false;
                                    $scope.success = false;
                                    $scope.thankupage = true;
                                }, function (error) {
                                    if (error) {
                                        Swal.fire({
                                            type: 'error',
                                            title: 'Oops...',
                                            text: JSON.stringify(error, null, 2)
                                        });
                                    }
                                });
                            });
                        });
                    });
                });
            });
        }

        function uploadPhoto(callback) {
            Uploader.uploadFilesToUrl([$scope.staff.photo], _env.apiUrl + '/uploads?attachable_type=staff_photo&attachable_id=' + $scope.staff.id, '', {
                'success': function () {
                    if (callback) callback();
                },
                'error': function (error) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(error, null, 2)
                    });
                }
            });
        }

        function uploadPassport(callback) {
            Uploader.uploadFilesToUrl([$scope.staff.passport], _env.apiUrl + '/uploads?attachable_type=passport&attachable_id=' + $scope.staff.id, '', {
                'success': function () {
                    if (callback) callback();
                },
                'error': function (error) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(error, null, 2)
                    });
                }
            });
        }

        function uploadTaxNumber(callback) {
            Uploader.uploadFilesToUrl([$scope.staff.tax_number], _env.apiUrl + '/uploads?attachable_type=tax_number&attachable_id=' + $scope.staff.id, '', {
                'success': function () {
                    if (callback) callback();
                },
                'error': function (error) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(error, null, 2)
                    });
                }
            });
        }

        function uploadInternationalPassport(callback) {
            if ($scope.staff.international_passport) {
                Uploader.uploadFilesToUrl([$scope.staff.international_passport], _env.apiUrl + '/uploads?attachable_type=international_passport&attachable_id=' + $scope.staff.id, '', {
                    'success': function () {
                        if (callback) callback();
                    },
                    'error': function (error) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(error, null, 2)
                        });
                    }
                });
            } else if (callback) {
                callback();
            }
        }

        $scope.fileDropped = function() {
            var file = $scope.uploadedFile;
            if (!file) return;

            var reader = new FileReader();

            reader.onload = function (e) {
                file.local_src = e.target.result;
                $scope.staff.photo = file;

                $scope.$apply();
            };
            reader.readAsDataURL(file);

            $scope.uploadedFile = null;
        };

        $scope.getPhoto = function () {
            if($scope.staff.photo) {
                if ($scope.staff.photo.file_url) {
                    return $scope.staff.photo.file_url;
                }
                return $scope.staff.photo.local_src;
            } else {
                return 'img/profile.png';
            }
        }
    }
})();