(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('ViewStaffCtrl', ViewStaffCtrl);

    function ViewStaffCtrl($scope, $state, $location, $http, $stateParams, _env, Uploader) {

        function getData() {
            $http.get(_env.apiUrl + '/staffs/' + $stateParams.id).then(function (response) {
                $scope.staff = response.data;
                $scope.staff.photo.file_url = 'https://hr-api.stylemix.net' + $scope.staff.photo.file_url;

                _.forEach($scope.staff.staff_ratings, function (staffRating) {
                    calculateRatingMediumValue(staffRating);
                });
                _.forEach($scope.staff.assessment_records, function (assessmentRecord) {

                    let fact = 0;
                    let max = 0;

                    _.forEach(assessmentRecord.marks, function (mark) {
                        max += mark.question.coefficient * (mark.question.coefficient > 0 ? 10 : 0);
                        fact += mark.question.coefficient * mark.mark;
                    });

                    assessmentRecord.finalMark = parseInt(100 * 100 * fact / max) / 100.0;
                });
            });
        }

        getData();

        $scope.addNote = function () {
            Swal.fire({
                input: 'textarea',
                inputPlaceholder: 'Type your note here...',
                showCancelButton: true
            }).then(function (result) {
                if (result.value) {

                    var data = {
                        staff_id: $scope.staff.id,
                        text: result.value
                    }
                    $http.post(_env.apiUrl + '/notes', data).then(function(response) {
                        Swal.fire({
                            position: 'top-end',
                            type: 'success',
                            title: 'Successfully saved!',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        $scope.staff.notes.push(response.data);
                    });
                }
            });
        }

        $scope.editNote = function (note) {
            Swal.fire({
                input: 'textarea',
                inputValue: note.text,
                inputPlaceholder: 'Type your note here...',
                showCancelButton: true
            }).then(function (result) {
                if (result.value) {

                    var data = {
                        staff_id: $scope.staff.id,
                        text: result.value
                    }
                    $http.put(_env.apiUrl + '/notes/' + note.id, data).then(function(response) {
                        Swal.fire({
                            position: 'top-end',
                            type: 'success',
                            title: 'Successfully saved!',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        note.text = result.value;
                    });
                }
            });
        }

        $scope.deleteNote = function (note) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $http.delete(_env.apiUrl + '/notes/' + note.id).then(function successCallback(response) {
                        $scope.staff.notes = _.filter($scope.staff.notes, function (item) {
                            return item.id != note.id;
                        });
                        Swal.fire({
                            position: 'top-end',
                            type: 'success',
                            title: 'Note has been deleted.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }, function errorCallback(response) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(response, null, 2)
                        });
                    });
                }
            });
        }

        $http.get(_env.apiUrl + '/positions').then(function (response) {
            $scope.positions = response.data;

            $scope.positionsControl = {
                data: _.map($scope.positions, function (position) {
                    return {
                        text: position.title,
                        id: position.id
                    };
                }),
                resetAfterSelection: true,
                onSelect: function (selectedData) {
                    $scope.attachPosition(selectedData.id);
                }
            };
            $scope.positionsControl.data.unshift({
                id: -1,
                text: 'Attach Position'
            });
        });

        $scope.detachPosition = function (positionId) {
            $http.delete(_env.apiUrl + '/staffs/position/' + $scope.staff.id + '/' + positionId).then(function () {
                $scope.staff.positions = _.filter($scope.staff.positions, function (p) {
                    return p.id != positionId;
                });
            }).then(function (error) {
                if (error) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(error, null, 2)
                    });
                }
            });
        }

        $scope.attachPosition = function (positionId) {
            $http.put(_env.apiUrl + '/staffs/position', { staff_id: $scope.staff.id, position_id: positionId }).then(function () {
                $scope.staff.positions.push(_.find($scope.positions, function (p) {
                    return p.id == positionId;
                }));
            }).then(function (error) {
                if (error) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(error, null, 2)
                    });
                }
            });
        }

        $scope.showDismissForm = function() {

            var kvos = _.filter($scope.staff.key_values_fields, function(kvo) {
                return (kvo.key == 'office_key' || kvo.key == 'kitchen' || kvo.key == 'inventory' || kvo.key == 'library') && kvo.value == 'Yes';
            });

            if (kvos.length > 0) {
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: "You can't dismiss employee before checking his Office Key, Kitchen, Inventory, Library status"
                });

                return;
            }

            $scope.chooseStaffControl = {
                data: _.map($scope.staffs, function (staff) {
                    return {
                        text: staff.name,
                        id: staff.id
                    };
                }),
                onSelect: function (selectedData) {
                    if (selectedData.id >= 0) {
                        $scope.note.staff_id = selectedData.id;
                    }
                }
            };
            $scope.chooseStaffControl.data.unshift({id: -1, text: "Choose Staff"});
            $scope.note = {};
            $('#modal-dismiss').modal('show');
        }

        $scope.submitDismissForm = function () {
            var data = {
                'dismiss_note': $scope.staff.dismiss_note,
                'dismissed_date': $scope.staff.dismissed_date
            };

            if (!data.dismiss_note|| !data.dismiss_note.trim()) {
                return;
            }

            if (!data.dismissed_date) {
                return;
            }

            $scope.proccessing = true;

            $http.post(_env.apiUrl + '/staffs/dismiss/' + $scope.staff.id, data).then(function(response) {
                $('#modal-dismiss').modal('hide');
                $scope.proccessing = false;
                $scope.note = null;

                getData();

                Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Successfully saved!',
                    showConfirmButton: false,
                    timer: 1000
                });
            });
        }

        $scope.getKeyValue = function(key) {
            if (!$scope.staff) return null;

            return _.find($scope.staff.key_values_fields, function(f) { return f.key == key; });
        }

        $scope.getKeyValueValueByKey = function(key) {
            var keyValue = $scope.getKeyValue(key);
            return keyValue ? keyValue.value : 'No';
        }

        $scope.changeKeyValue = function (key) {

            var keyValue = $scope.getKeyValue(key);
            if (!keyValue) {
                keyValue = {
                    key: key,
                    value: ''
                }
            }

            Swal.fire({
                input: 'text',
                inputValue: keyValue.value,
                inputPlaceholder: 'Type your value here...',
                showCancelButton: true
            }).then(function (result) {
                if (result.value) {
                    keyValue.value = result.value;
                    $http.put(_env.apiUrl + '/staffs/key_value_field/' + $scope.staff.id, keyValue).then(function(response) {
                        Swal.fire({
                            position: 'top-end',
                            type: 'success',
                            title: 'Successfully saved!',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        var found = false;
                        _.forEach($scope.staff.key_values_fields, function (keyValue) {
                            if (keyValue.key == key) {
                                found = true;
                                keyValue.value = result.value;
                            }
                        });
                        if (!found) {
                            $scope.staff.key_values_fields.push({
                                key: key,
                                value: result.value
                            });
                        }
                    });
                }
            });

        }

        $scope.changeValue = function (key) {
            let value = $scope.staff[key];

            Swal.fire({
                input: 'text',
                inputValue: value,
                inputPlaceholder: 'Type your value here...',
                showCancelButton: true
            }).then(function (result) {
                if (result.value) {
                    value = result.value;
                    let data = {
                        email: value
                    }
                    let path = "update_email";
                    if (key == 'name') {
                        data = {
                            name: value
                        }
                        path = "update_email";
                    }
                    $http.put(_env.apiUrl + '/staffs/' + path + '/' + $scope.staff.id, data).then(function(response) {
                        if (key == 'name') {
                            $scope.staff.name = value;
                        } else {
                            $scope.staff.email = value;
                        }
                        Swal.fire({
                            position: 'top-end',
                            type: 'success',
                            title: 'Successfully saved!',
                            showConfirmButton: false,
                            timer: 1000
                        });
                    });
                }
            });

        }

        $scope.changeCheckboxKeyValue = function (key, title) {
            var keyValue = $scope.getKeyValue(key);
            if (!keyValue) {
                keyValue = {
                    key: key,
                    value: ''
                }
            }

            Swal.fire({
                input: 'checkbox',
                inputValue: keyValue.value,
                inputPlaceholder: title,
                showCancelButton: true
            }).then(function (result) {
                keyValue.value = result.value == 0 ? 'No' : 'Yes';
                $http.put(_env.apiUrl + '/staffs/key_value_field/' + $scope.staff.id, keyValue).then(function(response) {
                    Swal.fire({
                        position: 'top-end',
                        type: 'success',
                        title: 'Successfully saved!',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    var found = false;
                    _.forEach($scope.staff.key_values_fields, function (keyValue) {
                        if (keyValue.key == key) {
                            found = true;
                            keyValue.value = keyValue.value;
                        }
                    });
                    if (!found) {
                        $scope.staff.key_values_fields.push({
                            key: key,
                            value: keyValue.value
                        });
                    }
                });
            });
        }

        $scope.showContractEndDateForm = function() {
            $scope.oldContractEndDate = $scope.staff.contract_end_date;
            $('#modal-contract-end-date').modal('show').on('hidden.bs.modal', function () {
                $scope.$apply(function() {
                    $scope.staff.contract_end_date = $scope.oldContractEndDate;
                });
            });
        }

        $scope.submitContractEndDateForm = function () {
            var data = {
                'contract_end_date': $scope.staff.contract_end_date
            };

            if (!data.contract_end_date) {
                return;
            }

            $scope.proccessing = true;
            $scope.oldContractEndDate = $scope.staff.contract_end_date;

            $http.put(_env.apiUrl + '/staffs/contact_date/' + $scope.staff.id, data).then(function(response) {
                $('#modal-contract-end-date').modal('hide');
                $scope.proccessing = false;
                Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Successfully saved!',
                    showConfirmButton: false,
                    timer: 1000
                });
            });
        }

        $scope.showProbationEndDateForm = function() {
            $('#modal-probation-end-date').modal('show');
        }

        $scope.submitProbationEndDateForm = function () {
            if (!$scope.staff.probation_end_date) return;

            var data = {
                'probation_end_date': angular.copy($scope.staff.probation_end_date)
            };

            $scope.proccessing = true;

            $http.put(_env.apiUrl + '/staffs/probation_end_date/' + $scope.staff.id, data).then(function(response) {
                $('#modal-probation-end-date').modal('hide');
                $scope.proccessing = false;
                Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Successfully saved!',
                    showConfirmButton: false,
                    timer: 1000
                });
            });
        }

        $scope.showEmploymentDateForm = function() {
            $('#modal-employment-date').modal('show');
        }

        $scope.submitEmploymentDateForm = function () {
            if (!$scope.staff.employment_date) return;

            var data = {
                'employment_date': angular.copy($scope.staff.employment_date)
            };

            $scope.proccessing = true;

            $http.put(_env.apiUrl + '/staffs/employment_date/' + $scope.staff.id, data).then(function(response) {
                $('#modal-employment-date').modal('hide');
                $scope.proccessing = false;
                Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Successfully saved!',
                    showConfirmButton: false,
                    timer: 1000
                });
            });
        }

        $scope.downloadFile = function(file) {
            var config = { responseType: 'blob' };
            $http.get(_env.apiUrl + '/uploads/download/' + file.id, config).then(function (response) {
                var headers = response.headers();
                var filename = file.file_name;
                var contentType = headers['content-type'];
                var newBlob = new Blob([response.data], { type: contentType });
                var url = window.URL.createObjectURL(newBlob);
                var link = document.createElement('a');
                link.href = url;
                link.download = filename;
                var clickEvent = new MouseEvent("click", {
                    "view": window,
                    "bubbles": true,
                    "cancelable": false
                });
                link.dispatchEvent(clickEvent);
            });
        }

        $http.get(_env.apiUrl + '/departments').then(function (response) {
            $scope.departments = response.data;
        });

        $http.get(_env.apiUrl + '/staff-categories').then(function (response) {
            $scope.categories = response.data;
        });

        $scope.onDepartmentChange = function () {
            if (!$scope.staff.department_id) return;

            var data = {
                staff_id: $scope.staff.id,
                department_id: $scope.staff.department_id
            }
            $scope.proccessing = true;
            $http.put(_env.apiUrl + '/staffs/department', data).then(function(response) {
                $scope.proccessing = false;
                Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Successfully saved!',
                    showConfirmButton: false,
                    timer: 1000
                });
            });
        }

        $scope.onCategoryChange = function () {
            if (!$scope.staff.category_id) return;

            var data = {
                staff_id: $scope.staff.id,
                category_id: $scope.staff.category_id
            }
            $scope.proccessing = true;
            $http.put(_env.apiUrl + '/staffs/category', data).then(function(response) {
                $scope.proccessing = false;
                Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Successfully saved!',
                    showConfirmButton: false,
                    timer: 1000
                });
            });
        }

        $scope.resendFillFormEmail = function () {
            $http.post(_env.apiUrl + '/staffs/resend-fill-form-email/' + $scope.staff.id).then(function(response) {
                $scope.proccessing = false;
                Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Successfully send!',
                    showConfirmButton: false,
                    timer: 1000
                });
            });
        }

        function calculateAssessmentMethodFinalMark(record) {
            let fact = 0;
            let max = 0;

            _.forEach(record.assessment_record.marks, function (mark) {
                max += mark.question.coefficient * (mark.question.coefficient > 0 ? 10 : 0);
                fact += mark.question.coefficient * mark.mark;
            });

            record.finalMark = parseInt(100 * 100 * fact / max) / 100.0;
        }

        function calculateRatingMediumValue(staffRating) {
            if (!staffRating.records) return 0;

            let max = 0;
            let fact = 0;

            _.forEach(staffRating.records, function (record) {
                calculateAssessmentMethodFinalMark(record);

                max += 100 * (record.coefficient ? record.coefficient : 1.0);
                fact += record.finalMark * (record.coefficient ? record.coefficient : 1.0);
            });

            staffRating.mediumValue = parseInt(100 * 100 * fact / max) / 100.0;
        }
    }
})();