(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('CurrentStaffsCtrl', CurrentStaffsCtrl);

    function CurrentStaffsCtrl($scope, $stateParams, $state, $location, $http, AuthenticationService, _env) {

        $http.get(_env.apiUrl + '/staffs').then(function (response) {

            $scope.staffs = _.map(response.data, function (staff) {
                staff.daysUntilContractEndDate = 0;
                if (staff.contract_end_date) {
                    staff.daysUntilContractEndDate = moment(staff.contract_end_date).diff(moment(), 'days');
                }

                return staff;
            });
        });

        $scope.isInProbationPeriod = function(employee) {
            if(employee.probation_end_date && moment(employee.probation_end_date).isAfter(moment())) {
                return true;
            }

            return false;
        }

        $scope.getPositionTitles = function (staff) {
            return _.map(staff.positions, function (p) {
                return p.title;
            }).join(', ');
        }

        $scope.showNoteForm = function() {
            $scope.chooseStaffControl = {
                data: _.map($scope.staffs, function (staff) {
                    return {
                        text: staff.name,
                        id: staff.id
                    };
                }),
                onSelect: function (selectedData) {
                    if (selectedData.id >= 0) {
                        $scope.note.staff_id = selectedData.id;
                    }
                }
            };
            $scope.chooseStaffControl.data.unshift({id: -1, text: "Choose Staff"});
            $scope.note = {};
            $('#modal-note').modal('show');
        }

        $scope.submitNoteForm = function () {
            if (!$scope.note) {
                return;
            }

            if (!$scope.note.text|| !$scope.note.text.trim()) {
                return;
            }

            if (!$scope.note.staff_id || $scope.note.staff_id < 0) {
                return;
            }

            $scope.proccessing = true;

            $http.post(_env.apiUrl + '/notes', $scope.note).then(function(response) {
                $('#modal-note').modal('hide');
                $scope.proccessing = false;
                $scope.note = null;
                Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Successfully saved!',
                    showConfirmButton: false,
                    timer: 1000
                });
            });
        }

        $scope.getKeyValue = function(staff, key) {
            if (!staff) return null;

            return _.find(staff.key_values_fields, function(f) { return f.key == key; });
        }

        $scope.getKeyValueValueByKey = function(staff, key) {
            var keyValue = $scope.getKeyValue(staff, key);
            return keyValue ? keyValue.value : 'No';
        }

    }
})();