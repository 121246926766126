(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('RatingsCtrl', RatingsCtrl);

    function RatingsCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        function get() {
            $http.get(_env.apiUrl + '/ratings').then(function (response) {
                $scope.items = response.data;
            });
        }

        get();

        $scope.showEditForm = function() {
            $scope.item = {};
            $('#modal-edit').modal('show');
        }

        $scope.save = function() {

            if (!$scope.item.title) {
                Swal.fire('Warning', 'Title is required', 'warning');
                return;
            }

            var data = {
                'title': $scope.item.title.trim(),
                'annual_evaluation': $scope.item.annual_evaluation ? $scope.item.annual_evaluation : false,
            };
            $http.post(_env.apiUrl + '/ratings', data).then(function(response) {
                $('#modal-edit').modal('hide');
                get();
            }, function (reason) {
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: JSON.stringify(reason.data, null, 2)
                });
            });

        }
    }
})();