(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .directive('errorable', errorable)
        .directive('backButton', backButton)
        .directive('linkify', linkify)
        .directive('attachment', attachment)
        .directive('select2', select2)
        .directive('fileModel', fileModel)
        .directive('timelineItem', timelineItem);

    function fileModel($parse) {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                var model = $parse(attrs.fileModel);
                var modelSetter = model.assign;

                element.bind('change', function() {
                    scope.$apply(function() {
                        modelSetter(scope, element[0].files[0]);
                    });
                });
            }
        }
    }

    function errorable() {
        return {
            restrict: 'A',
            scope: {
                error: "="
            },
            link: function(scope, element) {
                scope.$watch(function() {
                    element.removeClass('has-error');
                    $('.help-block', element).remove();
                    if (scope.error) {
                        element.addClass('has-error');
                        element.append('<span class="help-block">Поле обязательно для заполнения</span>');
                    }
                });
            }
        }
    }

    function backButton() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {

                element.html('Back');
                element.bind('click', goBack);

                function goBack() {
                    history.back();
                    scope.$apply();
                }
            }
        }
    }

    function linkify() {
        return {
            restrict: 'AE',
            link: function (scope, element) {
                scope.$watch('text', function (newValue, oldValue) {
                    element.html(newValue);
                    element.linkify();
                });
            },
            scope: {
                text: "@"
            }
        }
    }

    function attachment() {
        return {
            restrict: 'AE',
            replace: true,
            template: '<img class="attachment-img">',
            link: function (scope, element) {
                scope.$watch(function() {
                    return element.attr('src');
                }, function() {
                    var type = getFileType(element[0].src);
                    if (type != 'image' && type != 'unknown') {
                        element.attr('src', '/assets/img/file-types/' + type + '.png');
                    }
                });
                scope.$watch(function() {
                    return element.attr('fname');
                }, function(value) {
                    var type = getFileType(value);
                    if (type != 'image' && type != 'unknown') {
                        element.attr('src', '/assets/img/file-types/' + type + '.png');
                    }
                });
            },
            scope: {
                text: "@"
            }
        }
    }

    function select2() {
        return {
            restrict: 'A',
            scope: {
                config: "="
            },
            link: function(scope, element, attrs) {
                var initalized = false;
                function init() {
                    if (initalized) {
                        element.off('select2:select');
                        element.off('select2:close');
                        element.select2('destroy');
                        element.empty();
                    }
                    initalized = true;
                    element.select2({
                        data: scope.config.data
                    }).on('select2:select', function (e) {
                        var data = e.params.data;
                        if (scope.config.resetAfterSelection) {
                            if (parseInt(data.id) == -1) {
                                return;
                            }
                        }

                        if (scope.config.onSelect) {
                            scope.$apply(function () {
                                scope.config.onSelect(data);
                            });
                        }
                    }).on('select2:close', function (e) {
                        if (scope.config.resetAfterSelection) {
                            scope.$apply(function () {
                                element.val(-1);
                                element.trigger('change');
                            });
                        }
                    });
                }

                scope.$watch('config', function (newValue, oldValue) {
                    if(oldValue!=newValue) {
                        init();
                    }
                });

                init();
            }
        };
    }

    function timelineItem() {
        return {
            restrict: "E",
            replace: true,
            link: function(scope, elem, attrs) {
                scope.getClass = function(event) {
                    if(event.type == 'invite_sent') { return 'fa fa-bell bg-yellow'; }
                    if(event.type == 'profile_filled') { return 'fa fa-bell bg-blue'; }
                    if(event.type == 'staff_position_changed') { return 'fa fa-bell bg-aqua'; }
                    if(event.type == 'note_created') { return 'fa fa-bell bg-yellow'; }
                    if(event.type == 'staff_fired') { return 'fa fa-bell bg-red'; }
                    if(event.type == 'interview_created') { return 'fa fa-bell bg-aqua'; }
                }

                scope.getLink = function(event) {
                    if(event.type == 'invite_sent') { return ''; }
                    if(event.type == 'profile_filled') { return 'home.view-staff({id: ' + event.eventable.id + '})'; }
                    if(event.type == 'staff_position_changed') { return ''; }
                    if(event.type == 'note_created') { return  'home.view-staff({id: ' + event.eventable.staff_id + '})'; }
                    if(event.type == 'staff_fired') { return 'home.view-staff({id: ' + event.eventable.id + '})';; }
                    if(event.type == 'interview_created') { return 'home.interviews'; }
                }

                scope.hasBody = function(event) {
                    if(event.type == 'invite_sent') { return false; }
                    if(event.type == 'profile_filled') { return false; }
                    if(event.type == 'staff_position_changed') { return false; }
                    if(event.type == 'note_created') { return true; }
                    if(event.type == 'staff_fired') { return true; }
                    if(event.type == 'interview_created') { return true; }
                }

                scope.getBody = function(event) {
                    if(event.type == 'invite_sent') { return ''; }
                    if(event.type == 'profile_filled') { return ''; }
                    if(event.type == 'staff_position_changed') { return ''; }
                    if(event.type == 'note_created') { return event.eventable.text; }
                    if(event.type == 'staff_fired') { return event.eventable.dismiss_note; }
                    if(event.type == 'interview_created') { return event.eventable.text; }
                }

                scope.getContentUrl = function() {
                    if (attrs.type == 'time') {
                        return 'templates/feed/timeline/time.html';
                    } else {
                        return 'templates/feed/timeline/item.html';
                    }
                }

            },
            template: '<li ng-include="getContentUrl()"></li>'
        }
    }

})();