(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('AssessmentRecordDetailsCtrl', AssessmentRecordDetailsCtrl);

    function AssessmentRecordDetailsCtrl($scope, $state, $location, $http, AuthenticationService, _env, $stateParams) {

        getStaff(function () {
            getCategories(function () {
                if ($stateParams.id) {
                    $http.get(_env.apiUrl + '/assessment-records/' + $stateParams.id).then(function (response) {
                        $scope.item = response.data;
                        $scope.item.assessment_method_category_id = $scope.item.assessment_method.assessment_method_category_id;
                        $scope.item.assessment_method_id = $scope.item.assessment_method_id;
                        $scope.onCategoryChanged();
                        $scope.onMethodChanged();
                        _.forEach($scope.item.method.questions, function (question) {
                            var mark = _.find($scope.item.marks, function (mark) {
                                return mark.assessment_question_id == question.id;
                            });

                            if (mark) {
                                question.comment = mark.comment;
                                question.mark = mark.mark;
                            }
                        });
                    });
                } else {
                    $scope.item = {};
                }
            });
        });

        function getStaff(callback) {
            $http.get(_env.apiUrl + '/staffs?status=active|candidate').then(function (response) {
                $scope.staff = response.data;
                if (callback) callback();
            });
        }

        function getCategories(callback) {
            $http.get(_env.apiUrl + '/assessment-method-categories?withMethods=true').then(function (response) {
                $scope.categories = response.data;

                if(callback) callback();
            });
        }

        $scope.onCategoryChanged = function() {
            $scope.item.category = _.find($scope.categories, function (category) {
                return category.id == $scope.item.assessment_method_category_id;
            });
        }

        $scope.onMethodChanged = function() {
            $scope.item.method = _.find($scope.item.category.methods, function (method) {
                return method.id == $scope.item.assessment_method_id;
            });
        }

        $scope.delete = function () {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/assessment-records/' + $scope.item.id).then(function () {
                        Swal.fire({
                            type: 'success',
                            title: 'Item successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        }).then(function () {
                            history.back();
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }

        $scope.save = function () {
            if (!$scope.item.staff_id) {
                Swal.fire('Warning', 'Staff is required', 'warning');
                return;
            }

            if (!$scope.item.assessment_method_category_id) {
                Swal.fire('Warning', 'Category is required', 'warning');
                return;
            }

            if (!$scope.item.assessment_method_id) {
                Swal.fire('Warning', 'Method is required', 'warning');
                return;
            }

            if (!$scope.item.date) {
                Swal.fire('Warning', 'Assessment Date is required', 'warning');
                return;
            }

            var questionIsOk = true;
            _.forEach($scope.item.method.questions, function(question) {
                if (question.mark == null) {
                    Swal.fire('Warning', 'Question mark is required', 'warning');
                    questionIsOk = false;
                    return;
                }

                if (question.mark < 0 || question.mark > 10) {
                    Swal.fire('Warning', 'Question mark cannot be more than 10 or negative number', 'warning');
                    questionIsOk = false;
                    return;
                }
            });
            if (!questionIsOk) return;

            var data = {
                staff_id: $scope.item.staff_id,
                assessment_method_id: $scope.item.assessment_method_id,
                comment: $scope.item.comment,
                date: $scope.item.date,
                marks: _.map($scope.item.method.questions, function (question) {
                    return {
                        assessment_question_id: question.id,
                        comment: question.comment,
                        mark: question.mark
                    }
                })
            }

            if ($scope.item.id) {
                data.id = $scope.item.id;
            }

            $scope.proccessing = true;
            if ($scope.item.id) {
                $http.put(_env.apiUrl + '/assessment-records/' + $scope.item.id, data)
                    .then(function(response) {
                        $scope.proccessing = false;
                        Swal.fire({
                            position: 'top-end',
                            type: 'success',
                            title: 'Your work has been saved',
                            showConfirmButton: false,
                            timer: 1000
                        }).then(function () {
                            history.back();
                        });
                    }, function (reason) {
                        $scope.proccessing = false;
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });

            } else {
                $http.post(_env.apiUrl + '/assessment-records', data)
                    .then(function(response) {
                        $scope.proccessing = false;
                        Swal.fire({
                            position: 'top-end',
                            type: 'success',
                            title: 'Your work has been saved',
                            showConfirmButton: false,
                            timer: 1000
                        }).then(function () {
                            history.back();
                        });
                    }, function (reason) {
                        $scope.proccessing = false;
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });

            }
        }

    }
})();