(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('AssessmentRecordCtrl', AssessmentRecordCtrl);

    function AssessmentRecordCtrl($scope, $state, $location, $http, AuthenticationService, _env, $compile) {

        function get() {
            $http.get(_env.apiUrl + '/assessment-records').then(function (response) {
                $scope.items = _.map(response.data, function (item) {
                    item.finalMark = getFinalMark(item);

                    item.staff.position = _.map(item.staff.positions, function (p) {
                        return p.title;
                    }).join(', ');

                    return item;
                });

                initDataTable($scope.items);
            });
        }

        function getFinalMark(item) {
            var fact = 0;
            var max = 0;

            _.forEach(item.marks, function (mark) {
                max += mark.question.coefficient * (mark.question.coefficient > 0 ? 10 : 0);
                fact += mark.question.coefficient * mark.mark;
            });

            return 100.0 * fact / max;
        }

        get();

        function initDataTable(items) {
            $scope.options = {
                dom: 'tpr',
                buttons: [],
                pageLength: 50,
                order: [[6, 'desc']],
                bJQueryUI: true,
                bDestroy: true,
                aoColumns: [
                    {
                        "data": "staff.name",
                        "sTitle": "Staff"
                    },
                    {
                        "data": "staff.department.title",
                        "sTitle": "Department"
                    },
                    {
                        "data": "staff.position",
                        "sTitle": "Position"
                    },
                    {
                        "data": "assessment_method.assessment_method_category.title",
                        "sTitle": "Category"
                    },
                    {
                        "data": "assessment_method.title",
                        "sTitle": "Method"
                    },
                    {
                        "data": "finalMark",
                        "sTitle": "Final Mark",
                        "mRender": function (o) { return numeral(o).format('0.00'); },
                        "className": "text-right"
                    },
                    {
                        "data": "date",
                        "sTitle": "Date",
                        "mRender": function (o) { return formatDate(o, 'DD/MM/YYYY'); }
                    },
                    {
                        "mData": null,
                        "bSortable": false,
                        "mRender": function (o) { return '<a href ui-sref="home.assessment-record({id:'+ o.id +'})"><i class="fa fa-fw fa-pencil-square-o"></i></a>'; },
                        "className": "text-center"
                    }
                ],
                aaData: _.map(items, function (item) {
                    return item;
                }),
                initComplete: function () {
                    this.api().columns().every( function () {
                        var column = this;
                        var sTitle = $(column.header()).text().trim();
                        if (sTitle != 'Staff' && sTitle != 'Department' && sTitle != 'Position' && sTitle != 'Category' && sTitle != 'Method') {
                            return;
                        }

                        var select = $('<select class="form-control"><option value="">' + sTitle + '</option></select>')
                            .appendTo( $(column.header()).empty() )
                            .on( 'change', function () {
                                var val = $.fn.dataTable.util.escapeRegex($(this).val());

                                column
                                    .search( val ? ('^' + val + '$') : '', true, false)
                                    .draw();
                            } );

                        column.data().unique().sort().each( function ( d, j ) {
                            select.append( '<option value="'+d+'">'+d+'</option>' )
                        });

                        select.on( 'click', function(e) {
                            e.stopPropagation();
                        });
                    } );
                },
                createdRow: function(row) {
                    $compile(angular.element(row).contents())($scope);
                }
            };
            $scope.dataLoaded = true;
        }
    }
})();