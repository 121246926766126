(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .directive('tree', tree);

    function tree() {
        return function () {
            $scope.getFileTree = function () {
                $http.get(_env.apiUrl + '/resourceManager/tree').then(function(response){
                    window.tree = response.data;
                    $scope.treedata = response.data;
                });
            };

            $scope.lastClicked = null;
            $scope.buttonClick = function($event, node) {
                $scope.lastClicked = node;
                $event.stopPropagation();
            };
            $scope.showSelected = function(sel) {
                $scope.selectedNode = sel;
            };
        };
    }

})();