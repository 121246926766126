(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('InterviewsCtrl', InterviewsCtrl);

    function InterviewsCtrl($scope, $state, $location, $http, $stateParams, _env, Uploader) {

        function getData() {
            $http.get(_env.apiUrl + '/staffs?status=candidate|active|dismissed').then(function (response) {
                $scope.interviews = _.sortBy(_.filter(_.map(response.data, function (staff) {
                    staff.position = staff.positions && staff.positions.length > 0 ? staff.positions[0].title : null;
                    staff.note = staff.notes && staff.notes.length > 0 ? _.sortBy(staff.notes, "created_at")[0].text : null;
                    return staff;
                }), function (i) { return (i.summary_id !== 0) ? i.summary_id: 1; }), function (i) { return -moment(i.interview_date).valueOf(); });
            });
        }

        $http.get(_env.apiUrl + '/positions').then(function (response) {
            $scope.positions = response.data;
        });

        $http.get(_env.apiUrl + '/departments').then(function (response) {
            $scope.departments = response.data;
        });

        getData();

        $scope.showInterviewForm = function() {
            $scope.choosePositionControl = {
                data: _.map($scope.positions, function (position) {
                    return {
                        text: position.title,
                        id: position.id
                    };
                }),
                onSelect: function (selectedData) {
                    if (selectedData.id >= 0) {
                        $scope.interview.position_id = parseInt(selectedData.id);
                    }
                }
            };
            $scope.choosePositionControl.data.unshift({id: -1, text: "Choose Position"});
            $scope.interview = {};
            $('#modal-interview').modal('show');
        }

        $scope.submitInterviewForm = function () {
            if (!$scope.interview) {
                return;
            }

            if (!$scope.interview.position_id || $scope.interview.position_id < 0) {
                return;
            }

            if (!$scope.interview.department_id || $scope.interview.department_id < 0) {
                return;
            }

            if (!$scope.interview.name || !$scope.interview.name.trim()) {
                return;
            }

            if (!$scope.interview.personal_email || !$scope.interview.personal_email.trim()) {
                return;
            }

            if (!$scope.interview.phone || !$scope.interview.phone.trim()) {
                $scope.interview.phone = 'none';
            }

            if (!$scope.interview.note || !$scope.interview.note.trim()) {
                $scope.interview.note = "empty";
            }

            if (!$scope.interview.date) {
                var today = new Date();
                $scope.interview.date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+(today.getDate()+1);
            }

            if (!$scope.interview.summary) {
                //return;
            }

            $scope.proccessing = true;

            $http.post(_env.apiUrl + '/staffs/interview', $scope.interview).then(function(response) {
                uploadSummary(response.data.id, function () {
                    $('#modal-interview').modal('hide');
                    $scope.proccessing = false;
                    $scope.interview = null;
                    getData();
                    Swal.fire({
                        position: 'top-end',
                        type: 'success',
                        title: 'Successfully saved!',
                        showConfirmButton: false,
                        timer: 1000
                    });

                });
            });
        }

        function uploadSummary(interviewId, callback) {
            if ($scope.interview.summary) {
                Uploader.uploadFilesToUrl([$scope.interview.summary], _env.apiUrl + '/uploads?attachable_type=summary&attachable_id=' + interviewId, '', {
                    'success': function () {
                        if (callback) callback();
                    },
                    'error': function (error) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(error, null, 2)
                        });
                    }
                });
            } else if (callback) {
                callback();
            }
        }

        $scope.downloadFile = function(file) {
            var config = { responseType: 'blob' };
            $http.get(_env.apiUrl + '/uploads/download/' + file.id, config).then(function (response) {
                var headers = response.headers();
                var filename = file.file_name;
                var contentType = headers['content-type'];
                var newBlob = new Blob([response.data], { type: contentType });
                var url = window.URL.createObjectURL(newBlob);
                var link = document.createElement('a');
                link.href = url;
                link.download = filename;
                var clickEvent = new MouseEvent("click", {
                    "view": window,
                    "bubbles": true,
                    "cancelable": false
                });
                link.dispatchEvent(clickEvent);
            });
        }

        $scope.invite = function(interview) {
            Swal.fire({
                title: 'Invitation',
                text: 'Invite ' + interview.last_name + ' ' + interview.first_name + ' to fill out an application',
                input: 'email',
                inputPlaceholder: (interview.summary_id === 0) ? interview.personal_email : 'Type email here',
                inputValidator: function (value) {
                    if (!value && interview.summary_id !== 0) {
                        return 'You need to specify some email!'
                    }
                },
                showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Send',
                reverseButtons: true
            }).then(function(result) {
                if((interview.summary_id === 0)) {
                    var data = {
                        'staff_id': interview.id,
                        'personal_email': interview.personal_email
                    };

                    $http.post(_env.apiUrl + '/staffs/invite', data).then(function(response) {
                        Swal.fire({
                            type: 'success',
                            title: 'Invitation successfully sent!'
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                } else if (result.value && result.value.trim()) {
                    var data = {
                        'staff_id': interview.id,
                        'personal_email': result.value.trim()
                    };

                    $http.post(_env.apiUrl + '/staffs/invite', data).then(function(response) {
                        Swal.fire({
                            type: 'success',
                            title: 'Invitation successfully sent!'
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }
    }
})();