(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('AssessmentMethodDetailsCtrl', AssessmentMethodDetailsCtrl);

    function AssessmentMethodDetailsCtrl($scope, $state, $location, $http, AuthenticationService, _env, $stateParams) {

        $http.get(_env.apiUrl + '/assessment-method-categories').then(function (response) {
            $scope.categories = response.data;

            if ($stateParams.id) {
                $http.get(_env.apiUrl + '/assessment-methods/' + $stateParams.id).then(function (response) {
                    $scope.item = response.data;
                });
            } else {
                $scope.item = {
                    questions: []
                };
            }
        });

        $scope.addQuestion = function () {
            $scope.item.questions.push({});
        }

        $scope.removeQuestion = function ($index) {
            $scope.item.questions.splice($index, 1);
        }

        $scope.delete = function () {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/assessment-methods/' + $scope.item.id).then(function () {
                        Swal.fire({
                            type: 'success',
                            title: 'Item successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        }).then(function () {
                            history.back();
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }

        $scope.save = function () {
            if (!$scope.item.title|| !$scope.item.title.trim()) {
                Swal.fire('Warning', 'Title is required', 'warning');
                return;
            }

            if (!$scope.item.assessment_method_category_id) {
                Swal.fire('Warning', 'Category is required', 'warning');
                return;
            }

            if ($scope.item.questions.length == 0) {
                Swal.fire('Warning', 'At least one question is required', 'warning');
                return;
            }

            var questionIsOk = true;
            _.forEach($scope.item.questions, function(question) {
                if (!question.title|| !question.title.trim()) {
                    Swal.fire('Warning', 'Question title is required', 'warning');
                    questionIsOk = false;
                    return;
                }

                if (!question.description|| !question.description.trim()) {
                    Swal.fire('Warning', 'Question description is required', 'warning');
                    questionIsOk = false;
                    return;
                }

                if (question.coefficient == null) {
                    Swal.fire('Warning', 'Question coefficient is required', 'warning');
                    questionIsOk = false;
                    return;
                }
            });
            if (!questionIsOk) return;

            var data = {
                title: $scope.item.title,
                description: $scope.item.description,
                assessment_method_category_id: $scope.item.assessment_method_category_id,
                questions: _.map($scope.item.questions, function (question) {
                    var q = {
                        title: question.title,
                        description: question.description,
                        coefficient: question.coefficient
                    }
                    if (question.id) {
                        q.id = question.id;
                    }
                    return q;
                })
            }

            if ($scope.item.id) {
                data.id = $scope.item.id;
            }

            $scope.proccessing = true;
            if ($scope.item.id) {
                $http.put(_env.apiUrl + '/assessment-methods/' + $scope.item.id, data)
                    .then(function(response) {
                        $scope.proccessing = false;
                        Swal.fire({
                            position: 'top-end',
                            type: 'success',
                            title: 'Your work has been saved',
                            showConfirmButton: false,
                            timer: 1000
                        }).then(function () {
                            history.back();
                        });
                    }, function (reason) {
                        $scope.proccessing = false;
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });

            } else {
                $http.post(_env.apiUrl + '/assessment-methods', data)
                    .then(function(response) {
                        $scope.proccessing = false;
                        Swal.fire({
                            position: 'top-end',
                            type: 'success',
                            title: 'Your work has been saved',
                            showConfirmButton: false,
                            timer: 1000
                        }).then(function () {
                            history.back();
                        });
                    }, function (reason) {
                        $scope.proccessing = false;
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });

            }
        }

    }
})();