(function () {
    'use strict';
    
    
    var env = {};
    if(window){  
        Object.assign(env, window._env);
    }
    
    angular
        .module('hrc.bimiboo.com', [
            'ui.router', 'ngSanitize', 'templates', 'bw.paging', 'schemaForm', 'ui.ace', 'treeControl', 'ui.sortable'
        ])
        .constant('_env', env)
        .config(httpProviderConfig)
        .config(routeProviderConfig)
        .run(run);
    
    function httpProviderConfig($httpProvider) {
        $httpProvider.interceptors.push(function($q) {
            return {
                'responseError': function(rejection) {
                    var defer = $q.defer();
                    if(rejection.status == 401) {
                        window.location.assign('#/login');
                    }
                    defer.reject(rejection);

                    return defer.promise;
                },
                'request': function(config) {
                    config.headers = config.headers || {};
                    if (localStorage.getItem('token')) {
                        config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
                    }
                    return config || $q.when(config);
                }
            };
        });
    }

    function routeProviderConfig($stateProvider, $urlRouterProvider) {
        
        $urlRouterProvider
            .when('', '/feed')
            .otherwise('/feed');

        $stateProvider
            .state('login', {
                url: '/login',
                templateUrl: 'templates/login/view.html',
                controller: 'LoginCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: true
                }
            })
            .state('home', {
                url: '',
                templateUrl: 'templates/home/view.html',
                controller: 'HomeCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.feed', {
                url: '/feed',
                templateUrl: 'templates/feed/view.html',
                controller: 'FeedCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.current-staff', {
                url: '/current-staff',
                templateUrl: 'templates/staffs/current-staff/view.html',
                controller: 'CurrentStaffsCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.add-staff', {
                url: '/add-staff',
                templateUrl: 'templates/staffs/add-staff/view.html',
                controller: 'AddStaffsCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.archived-staff', {
                url: '/archived-staff',
                templateUrl: 'templates/staffs/archived-staff/view.html',
                controller: 'ArchivedStaffCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.interviews', {
                url: '/interviews',
                templateUrl: 'templates/interviews/list/view.html',
                controller: 'InterviewsCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.view-staff', {
                url: '/staff/view/:id',
                templateUrl: 'templates/staffs/view/view.html',
                controller: 'ViewStaffCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.edit-staff', {
                url: '/staffs/:id',
                templateUrl: 'templates/staffs/edit/view.html',
                controller: 'EditStaffCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })

            .state('home.position-list', {
                url: '/position-list',
                templateUrl: 'templates/positions/list/view.html',
                controller: 'PositionListCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.create-position', {
                url: '/create-position',
                templateUrl: 'templates/positions/create/view.html',
                controller: 'CreatePositionCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.edit-position', {
                url: '/edit-position/:positionId',
                templateUrl: 'templates/positions/edit/view.html',
                controller: 'EditPositionCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })

            .state('home.departments', {
                url: '/departments',
                templateUrl: 'templates/departments/view.html',
                controller: 'DepartmentCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })

            .state('home.categories', {
                url: '/categories',
                templateUrl: 'templates/categories/view.html',
                controller: 'CategoryCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })

            .state('home.assessment-method-categories', {
                url: '/assessment-method-categories',
                templateUrl: 'templates/assessment-method-categories/view.html',
                controller: 'AssessmentMethodCategoryCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })

            .state('home.assessment-methods', {
                url: '/assessment-methods',
                templateUrl: 'templates/assessment-methods/list/view.html',
                controller: 'AssessmentMethodCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.assessment-method', {
                url: '/assessment-method/:id',
                templateUrl: 'templates/assessment-methods/details/view.html',
                controller: 'AssessmentMethodDetailsCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })

            .state('home.assessment-records', {
                url: '/assessment-records',
                templateUrl: 'templates/assessment-records/list/view.html',
                controller: 'AssessmentRecordCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.assessment-record', {
                url: '/assessment-record/:id',
                templateUrl: 'templates/assessment-records/details/view.html',
                controller: 'AssessmentRecordDetailsCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })

            .state('home.ratings', {
                url: '/ratings',
                templateUrl: 'templates/ratings/list/view.html',
                controller: 'RatingsCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.rating', {
                url: '/rating/:id',
                templateUrl: 'templates/ratings/details/view.html',
                controller: 'RatingCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            });

    }

    function run($rootScope, $timeout) {

        $rootScope.formatDate = formatDate;

        $rootScope.$on('$routeChangeStart', function(event, next, current) {

            if (next.restrictions.ensureAuthenticated) {
                if (!localStorage.getItem('token')) {
                    $location.path('/login');
                }
            }

            if (next.restrictions.loginRedirect) {
                if (localStorage.getItem('token')) {
                    $location.path('/home');
                }
            }

        });

        $rootScope.$on('$stateChangeSuccess', function () {
            $timeout(function () {
                // fix sidebar
                var neg = $('.main-header').outerHeight() + $('.main-footer').outerHeight()
                var window_height = $(window).height()
                var sidebar_height = $('.sidebar').height()

                if ($('body').hasClass('fixed')) {
                    $('.content-wrapper, .right-side').css('min-height', window_height - $('.main-footer').outerHeight())
                } else {
                    if (window_height >= sidebar_height) {
                        $('.content-wrapper, .right-side').css('min-height', window_height - neg)
                    } else {
                        $('.content-wrapper, .right-side').css('min-height', sidebar_height)
                    }
                }
            })
        })
    }
    
})();
