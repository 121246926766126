(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('ArchivedStaffCtrl', ArchivedStaffCtrl);

    function ArchivedStaffCtrl($scope, $state, $location, $http, $stateParams, _env) {
        $http.get(_env.apiUrl + '/staffs?status=dismissed').then(function (response) {
            $scope.staffs = response.data;
        });

        $scope.getPositionTitles = function (staff) {
            return _.map(staff.positions, function (p) {
                return p.title;
            }).join(', ');
        }

    }
})();