(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .directive('filedrop', filedrop);

    function filedrop($parse, $document) {
        return {
            restrict: "A",
            link: function ($scope, element, attrs) {
                var onFileDrop = $parse(attrs.onFileDrop);

                //When an item is dragged over the document
                var onDragOver = function (e) {
                    e.preventDefault();
                    angular.element('body').addClass("dragOver");
                };

                //When the user leaves the window, cancels the drag or drops the item
                var onDragEnd = function (e) {
                    e.preventDefault();
                    angular.element('body').removeClass("dragOver");
                };

                //When a file is dropped
                var loadFile = function (file) {
                    $scope.uploadedFile = file;
                    $scope.$apply(onFileDrop($scope));
                };

                //Dragging begins on the document
                $document.bind("dragover", onDragOver);

                //Dragging ends on the overlay, which takes the whole window
                element.bind("dragleave", onDragEnd)
                    .bind("drop", function (e) {
                        onDragEnd(e);
                        loadFile(e.originalEvent.dataTransfer.files[0]);
                    });

                element.append('<input class="input hidden" type="file"/>');
                $(".input", element).bind("change", function (e) {
                    if (e.target.files && e.target.files.length > 0) {
                        var file = e.target.files[0];
                        $scope.uploadedFile = file;
                        $scope.$apply(onFileDrop($scope));
                    }
                });

                element.bind("click", function () {
                    $(".input", element)[0].click();
                });
            }
        };
    }

})();