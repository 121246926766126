(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('LoginCtrl', LoginCtrl);

    function LoginCtrl($scope, $location, $http, AuthenticationService, focus) {

        $scope.login = function() {

            if (!$scope.email || !$scope.password || $scope.password.length < 6) return;

            $scope.loading = true;

            AuthenticationService.Login($scope.email, $scope.password, function() {
                $scope.loading = false;
                window.location.assign('#/timeline');
            }, function (response) {
                $scope.loading = false;
                var error = response.data && response.data.error ? response.data.error : JSON.stringify(response.data, null, 2);
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: JSON.stringify(error, null, 2)
                }).then(function() {
                    if (response.status == 401) {
                        focus('password');
                    } else {
                        focus('email');
                    }
                });
            });
        }
    }
})();