(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('RatingCtrl', RatingCtrl);

    function RatingCtrl($scope, $state, $location, $http, AuthenticationService, _env, $stateParams, $compile) {

        function get() {
            $http.get(_env.apiUrl + '/ratings/' + $stateParams.id).then(function (response) {
                $scope.item = response.data;
                _.forEach($scope.item.staff_ratings, function (staffRating) {
                    _.forEach(staffRating.records, function(record) {
                        record.assessment_record.finalMark = getFinalMark(record.assessment_record);
                    });
                    staffRating.mediumValue = getMediumValue(staffRating.records);
                    staffRating.staff.position = _.map(staffRating.staff.positions, function (p) {
                        return p.title;
                    }).join(", ");
                });
                initDataTable($scope.item.staff_ratings);
            });
        }

        function getStaff(callback) {
            $http.get(_env.apiUrl + '/staffs?status=active|candidate').then(function (response) {
                $scope.staff = response.data;
                if (callback) callback();
            });
        }

        getStaff(function () {
            get();
        });



        function initDataTable(items) {
            $scope.options = {
                dom: 'tpr',
                buttons: [],
                pageLength: 50,
                order: [[3, 'desc']],
                bJQueryUI: true,
                bDestroy: true,
                aoColumns: [
                    {
                        "data": "staff.department.title",
                        "sTitle": "Department"
                    },
                    {
                        "data": null,
                        "bSortable": false,
                        "sTitle": "Staff",
                        "mRender": function (o, type, full) {
                            return '<div>' + full.staff.name + '</div>' + '<div class="text-muted">' + full.staff.position + '</div>';
                        }
                    },
                    {
                        "data": null,
                        "sTitle": "Methods",
                        "bSortable": false,
                        "mRender": function (o, type, full) {
                            var i = 0;
                            var content = _.map(full.records, function (record) {
                                i++;
                                return '<div>'
                                    + i
                                    + '. <a style="text-decoration: underline;" ui-sref="home.assessment-record({id: ' + record.assessment_record.id + '})">'
                                    + record.assessment_record.assessment_method.title
                                    + '</a>'
                                    + '- ' + record.assessment_record.finalMark + '%'
                                    + '</div>';
                            }).join('');

                            return content;
                        },
                    },
                    {
                        "data": "mediumValue",
                        "sTitle": "Final Mark",
                        "mRender": function (o) { return numeral(o).format('0.00'); },
                        "className": "text-right medium-value"
                    },
                    {
                        "data": "percent",
                        "sTitle": "Promotion (%)",
                        "className": "text-right" + ($scope.item.annual_evaluation != null && $scope.item.annual_evaluation ? "" : " hide_column"),
                        "mRender": function (o) {
                            if (o && o != 0) {
                                return numeral(o).format('0.');
                            }

                            return 'N/A';
                        }
                    },
                    {
                        "data": "date",
                        "sTitle": "Date",
                        "mRender": function (o) { return formatDate(o, 'DD/MM/YYYY'); }
                    },
                    {
                        "mData": null,
                        "bSortable": false,
                        "mRender": function (o) { return '<a href ng-click="showStaffRating('+ o.id +')"><i class="fa fa-fw fa-pencil-square-o"></i></a>'; },
                        "className": "text-center"
                    }
                ],
                aaData: _.map(items, function (item) {
                    return item;
                }),
                initComplete: function () {
                    this.api().columns().every( function () {
                        var column = this;
                        var sTitle = $(column.header()).text().trim();
                        if (sTitle != 'Department') {
                            return;
                        }

                        var select = $('<select class="form-control"><option value="">' + sTitle + '</option></select>')
                            .appendTo( $(column.header()).empty() )
                            .on( 'change', function () {
                                var val = $.fn.dataTable.util.escapeRegex($(this).val());

                                column
                                    .search( val ? ('^' + val + '$') : '', true, false)
                                    .draw();
                            } );

                        column.data().unique().sort().each( function ( d, j ) {
                            select.append( '<option value="'+d+'">'+d+'</option>' )
                        });

                        select.on( 'click', function(e) {
                            e.stopPropagation();
                        });
                    } );
                },
                createdRow: function(row) {
                    $compile(angular.element(row).contents())($scope);
                }
            };
            $scope.dataLoaded = true;
        }

        $scope.showEditForm = function() {
            $('#modal-edit').modal('show');
        }

        $scope.save = function() {

            if (!$scope.item.title) {
                Swal.fire('Warning', 'Title is required', 'warning');
                return;
            }

            var data = {
                'title': $scope.item.title.trim(),
                'annual_evaluation': $scope.item.annual_evaluation ? $scope.item.annual_evaluation : false
            };
            $http.put(_env.apiUrl + '/ratings/' + $scope.item.id, data).then(function(response) {
                get();
                $('#modal-edit').modal('hide');
            }, function (reason) {
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: JSON.stringify(reason.data, null, 2)
                });
            });

        }

        $scope.delete = function () {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/ratings/' + $scope.item.id).then(function () {
                        Swal.fire({
                            type: 'success',
                            title: 'Item successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        }).then(function () {
                            history.back();
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }

        function getFinalMark(item) {
            var fact = 0;
            var max = 0;

            _.forEach(item.marks, function (mark) {
                max += mark.question.coefficient * (mark.question.coefficient > 0 ? 10 : 0);
                fact += mark.question.coefficient * mark.mark;
            });

            return parseInt(100 * 100 * fact / max) / 100.0;
        }

        function getMediumValue(records) {
            var max = 0;
            var fact = 0;

            _.forEach(records, function (record) {
               max += 100 * (record.coefficient ? record.coefficient : 1.0);
                fact += record.assessment_record.finalMark * (record.coefficient ? record.coefficient : 1.0);
            });

            return parseInt(100 * 100 * fact / max) / 100.0;
        }

        function getStaffAssessmentRecords() {
            $http.get(_env.apiUrl + '/assessment-records?staffId=' + $scope.staffRating.staff_id).then(function (response) {
                var selectedRecords = $scope.staffRating.records;
                $scope.staffRating.records = _.map(response.data, function (item) {
                    item.finalMark = getFinalMark(item);
                    var selectedRecord = _.find(selectedRecords, function (record) {
                       return record.assessment_record_id == item.id;
                    });
                    if (selectedRecord) {
                        item.coefficient = selectedRecord.coefficient;
                        item.selected = true;
                    } else {
                        item.coefficient = 1.0;
                        item.selected = false;
                    }
                    return item;
                });
            });
        }
        $scope.onStaffChanged = function() {
            getStaffAssessmentRecords($scope.staffRating.staff_id);
        }
        
        $scope.showStaffRating = function (staffRatingId) {
            var staffRating = _.find($scope.item.staff_ratings, function (sr) {
                return sr.id == staffRatingId;
            });

            if (staffRating) {
                $scope.staffRating = angular.copy(staffRating);
                getStaffAssessmentRecords();
            } else {
                $scope.staffRating = {
                    rating_id: $scope.item.id
                };
            }

            $scope.staffRating.getMediumValue = function() {
                if (!$scope.staffRating.records) return 0;

                var max = 0;
                var fact = 0;

                _.forEach($scope.staffRating.records, function (record) {
                    if (record.selected) {
                        max += 100 * (record.coefficient ? record.coefficient : 1.0);
                        fact += record.finalMark * (record.coefficient ? record.coefficient : 1.0);
                    }
                });

                return parseInt(100 * 100 * fact / max) / 100.0;
            }

            $('#modal-staff-rating').modal('show');
        }

        $scope.saveStaffRating = function () {
            if (!$scope.staffRating) {
                return;
            }

            if (!$scope.staffRating.staff_id) {
                Swal.fire('Warning', 'Staff is required', 'warning');
                return;
            }

            if (!$scope.staffRating.date) {
                Swal.fire('Warning', 'Date is required', 'warning');
                return;
            }

            if (!$scope.staffRating.percent) {
                $scope.staffRating.percent = 0;
            }

            if (!$scope.staffRating.comment) {
                Swal.fire('Warning', 'Note is required', 'warning');
                return;
            }

            if(_.filter($scope.staffRating.records, function(record) {
                return record.selected;
            }).length < 1) {
                Swal.fire('Warning', 'No record selected', 'warning');
            }

            var data = {
                rating_id: $scope.item.id,
                staff_id: $scope.staffRating.staff_id,
                date: $scope.staffRating.date,
                percent: $scope.staffRating.percent,
                comment: $scope.staffRating.comment,
                records: _.map(_.filter($scope.staffRating.records, function(record) { return record.selected; }), function (record) {
                    return {
                        record_id: record.id,
                        coefficient: (record.coefficient ? record.coefficient : 1.0)
                    }
                })
            }

            $scope.proccessing = true;

            if ($scope.staffRating.id) {
                $http.put(_env.apiUrl + '/staff-ratings/' + $scope.staffRating.id, data).then(function(response) {
                    get();
                    $scope.proccessing = false;
                    $('#modal-staff-rating').modal('hide');

                    Swal.fire({
                        position: 'top-end',
                        type: 'success',
                        title: 'Successfully saved!',
                        showConfirmButton: false,
                        timer: 1000
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/staff-ratings', data).then(function(response) {
                    get();
                    $scope.proccessing = false;
                    $('#modal-staff-rating').modal('hide');

                    Swal.fire({
                        position: 'top-end',
                        type: 'success',
                        title: 'Successfully saved!',
                        showConfirmButton: false,
                        timer: 1000
                    });
                });
            }

        }

        $scope.deleteStaffRating = function () {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/staff-ratings/' + $scope.staffRating.id).then(function () {
                        get();
                        $('#modal-staff-rating').modal('hide');
                        Swal.fire({
                            type: 'success',
                            title: 'Item successfully deleted',
                            position: 'top-end',
                            timer: 1000
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });

        }

    }
})();