(function () {
    'use strict';

    angular
        .module('hrc.bimiboo.com')
        .controller('CreatePositionCtrl', CreatePositionCtrl);

    function CreatePositionCtrl($scope, $state, $location, $http, _env) {

        $scope.position = {
            title: '',
            duties: []
        };

        _.forEach($scope.position.duties, function(duty) {
            if (!duty.instructions) duty.instructions = [];
        });

        $scope.save = function () {

            if (!$scope.position.title|| !$scope.position.title.trim()) {
                Swal.fire('Warning', 'Position title is required', 'warning');
                return;
            }

            if ($scope.position.duties.length == 0) {
                Swal.fire('Warning', 'Specify at least one duty', 'warning');
                return;
            }

            _.forEach($scope.position.duties, function(duty) {
                if (!duty.instructions) duty.instructions = [];
            });

            $scope.proccessing = true;
            $http.post(_env.apiUrl + '/positions', $scope.position).then(function(response) {
                $scope.proccessing = false;
                Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1000
                }).then(function () {
                    history.back();
                });
            }, function (response) {
                $scope.proccessing = false;
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: JSON.stringify(reason.data, null, 2)
                });
            });
        }

        $scope.editDuty = function (duty) {
            var value = duty.value;
            Swal.fire({
                title: 'Edit Duty',
                input: 'text',
                inputValue: value,
                inputPlaceholder: 'Type duty here ...',
                inputValidator: function (value) {
                    if (!value) {
                        return 'You need to specify some duty!'
                    }
                },
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Save',
                reverseButtons: true
            }).then(function(result) {
                if (result.value && result.value.trim()) {
                    $scope.$apply(function () {
                        duty.value = result.value;
                    })

                }
            });
        }

        $scope.deleteDuty = function (atIndex, duty) {
            Swal.fire({
                title: 'Are you sure?',
                text: duty.value,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
                reverseButtons: true
            }).then(function (result) {
                if (result.value) {
                    $scope.$apply(function () {
                        $scope.position.duties.splice(atIndex, 1);
                    });
                }
            });
        }

        $scope.addDuty = function () {
            Swal.fire({
                title: 'Add Duty',
                input: 'text',
                inputPlaceholder: 'Type duty here ...',
                inputValidator: function (value) {
                    if (!value) {
                        return 'You need to specify some duty!'
                    }
                },
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Save',
                reverseButtons: true
            }).then(function(result) {
                if (result.value && result.value.trim()) {
                    $scope.$apply(function () {
                        $scope.position.duties.push({
                            value: result.value.trim(),
                            order: $scope.position.duties.length + 1
                        });
                    });
                }
            });
        }

        $scope.editInstruction = function (instruction) {
            var value = instruction.value;
            Swal.fire({
                title: 'Edit Duty',
                input: 'text',
                inputValue: value,
                inputPlaceholder: 'Type duty here ...',
                inputValidator: function (value) {
                    if (!value) {
                        return 'You need to specify some duty!'
                    }
                },
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Save',
                reverseButtons: true
            }).then(function(result) {
                if (result.value && result.value.trim()) {
                    $scope.$apply(function () {
                        instruction.value = result.value;
                    })

                }
            });
        }

        $scope.deleteInstruction = function (atIndex, duty, instruction) {
            Swal.fire({
                title: 'Are you sure?',
                text: instruction.value,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
                reverseButtons: true
            }).then(function (result) {
                if (result.value) {
                    $scope.$apply(function () {
                        duty.instructions.splice(atIndex, 1);
                    });
                }
            });
        }

        $scope.addInstruction = function (duty) {
            Swal.fire({
                title: 'Add Duty Instruction',
                input: 'text',
                inputPlaceholder: 'Type instruction here ...',
                inputValidator: function (value) {
                    if (!value) {
                        return 'You need to specify some instruction!'
                    }
                },
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Save',
                reverseButtons: true
            }).then(function(result) {
                if (result.value && result.value.trim()) {
                    $scope.$apply(function () {
                        if (!duty.instructions) duty.instructions = [];
                        duty.instructions.push({
                            value: result.value.trim(),
                            order: duty.instructions.length + 1
                        });
                    });
                }
            });
        }
    }
})();